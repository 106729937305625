

import jsPDF from "jspdf";
const moment = require('moment')
const footer = require("@/assets/images/footerReport.png");
const logo = require("@/assets/images/logoRed.png");

const docOptions = {
    orientation: "portrait",
    format: 'a4',
    unit: "mm", // 210 x 297
    autoPaging: 'text',
};

var doc = new jsPDF(docOptions);

const pageHeight = doc.internal.pageSize.height;
const pageWidth = doc.internal.pageSize.width;

function _printNormalAndBold(inputValue, startX, Y) {
    const arrayOfNormalAndBoldText = inputValue.split('**');
    arrayOfNormalAndBoldText.map((text, i) => {
        doc.setFont(undefined, 'bold');
        // every even item is a normal font weight item
        if (i % 2 === 0) {
            doc.setFont(undefined, 'normal');
        }
        doc.text(text, startX, Y);
        startX = startX + doc.getTextWidth(text);
    });
}

function printPage(receiptItem) {
    doc.setFontSize(10);
    let img = new Image();
    img.src = logo;

    doc.addImage(img, "PNG", 2, 2, 57, 30, '', 'FAST');

    let Y = 10;
    doc.setFont(undefined, 'bold').text("RECIBO DE CARREGAMENTO", 136, Y).setFont(undefined, 'normal');
    _printNormalAndBold("**Sollus** Indústria Eletrônica Ltda.", 60, Y);

    Y += 5;
    doc.text("CNPJ/MF: 22.108.794/0001-44", 60, Y);
    Y += 10;
    doc.text("SAC: +55 (35) 3473-0824", 60, Y);

    const X = 45;
    Y = 80;
    const BOX_H = 16;
    const BOX_W = 60;
    const BORDER = 5;

    const BORDER_TOP_TITLE = 7.5;
    const BORDER_TOP_SUBTITLE = 12;

    doc.setFillColor(255, 88, 91);

    doc.roundedRect(X, Y, 59, 15, 1, 1, 'F');
    doc.roundedRect(X, Y + BOX_H, 59, 15, 1, 1, 'F');
    doc.roundedRect(X + BOX_W, Y, 59, 15, 1, 1, 'F');
    doc.roundedRect(X + BOX_W, Y + BOX_H, 59, 15, 1, 1, 'F');

    doc.setTextColor(255, 255, 255);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(14);

    doc.setFontSize(14);
    doc.text(`${receiptItem.charger}`, X + BORDER, Y + BORDER_TOP_TITLE);
    doc.setFontSize(8);
    doc.text("CARREGADOR", X + BORDER, Y + BORDER_TOP_SUBTITLE);

    doc.setFontSize(14);
    doc.text(`${moment(receiptItem.dateFinish).format('DD/MM/YYYY HH:mm:ss')}`, X + BORDER + BOX_W, Y + BORDER_TOP_TITLE);
    doc.setFontSize(8);
    doc.text("Data", X + BORDER + BOX_W, Y + BORDER_TOP_SUBTITLE);

    doc.setFontSize(14);
    doc.text(`R$ ${receiptItem.value ? Number.parseFloat(receiptItem.value).toFixed(2) : 0}`, X + BORDER, Y + BORDER_TOP_TITLE + BOX_H);
    doc.setFontSize(8);
    doc.text("Valor Total", X + BORDER, Y + BORDER_TOP_SUBTITLE + BOX_H);

    doc.setFontSize(14);
    doc.text(`${receiptItem.consumption ? receiptItem.consumption : 0} kWh`, X + BORDER + BOX_W, Y + BORDER_TOP_TITLE + BOX_H);
    doc.setFontSize(8);
    doc.text("Consumo", X + BORDER + BOX_W, Y + BORDER_TOP_SUBTITLE + BOX_H);

    img.src = footer;
    doc.addImage(img, "PNG", 0, pageHeight - 125, pageWidth, 125, '', 'FAST');

    return doc;
}

export default {
    create: (receiptItem) => {
        doc = new jsPDF(docOptions);

        doc = printPage(receiptItem)

        return doc;
    },
};